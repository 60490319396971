'use client';

import React from 'react';

import PropTypes from 'prop-types';

import Text from 'components/ui/Text';

import styles from './SignUpTag.module.scss';

const SignUpTag = ({ onClick }) => {
    return (
        <div className={styles.root}>
            <button className={styles.button} onClick={onClick}>
                <Text className={styles.label} baseTheme="labelMedium">
                    Sign Me up
                </Text>
                <svg
                    className={styles.icon}
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="12.5"
                        y="1"
                        width="18"
                        height="12"
                        rx="2.25"
                        transform="rotate(90 12.5 1)"
                        fill="#BADAF3"
                        stroke="#001E60"
                    />
                    <path
                        d="M10.0996 3.72498L5.7099 8.8263C5.12909 9.50126 5.12909 10.4995 5.7099 11.1744L10.0996 16.2758"
                        stroke="#001E60"
                        strokeLinecap="round"
                    />
                    <path
                        d="M2.89941 16.2759L6.49941 12.0923M2.89941 3.72516L6.49941 7.90875"
                        stroke="#001E60"
                        strokeLinecap="round"
                    />
                </svg>

                <span className="sr-only">Expand Alert</span>
            </button>
        </div>
    );
};

SignUpTag.propTypes = {
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
};

export default SignUpTag;
